export const device = {
  mobile: '(min-width: 280px) and (max-width: 699px)',
  tablet: '(min-width: 700px) and (max-width: 1100px)',
  desktop: '(max-width: 1101px)',
};

export const colors = {
  primary: '#191919',
  hover: '#58595B',
};
