import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { archivosFiles } from '../../../../services/api';
import { saveVideosAction } from '../../../../store/actions';
import useVideosHook from '../../../web/landing-news/portafolio/VideosLinksHook';

const FormVideos = ({ isModal }) => {
  const dispatch = useDispatch();
  const videosStore = useSelector((state) => state.videos);

  const [file, setFile] = useState();
  const [image, setImage] = useState();

  const { tiposArray, categoriasArray } = useVideosHook();

  const [preview, setPreview] = useState();
  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const [titulo, setTitulo] = useState('');
  const [tituloError, setTituloError] = useState(false);

  const [tipo, setTipo] = useState(tiposArray[0].id);
  const [categorias, setCategorias] = useState([]);

  const [desc, setDesc] = useState(null);
  const [link, setLink] = useState('');

  const validarInputs = () => {
    const tituloValid = titulo === '';
    setTituloError(tituloValid);
    return !tituloValid;
  };

  const [loadingSave, setLoadingSave] = useState(false);

  const handlerSubmit = async () => {
    if (categorias.length === 0) {
      return alert('Falta seleccionar al menos una categoría');
    }

    if (link === '') {
      return alert('Falta agregar el link del video');
    }

    const valid = await validarInputs();
    let res;
    if (valid) {
      let data = {
        titulo,
        tipo,
        categorias: categorias.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
        ),
        desc,
        link,
      };
      setLoadingSave(true);
      if (videosStore.activo) {
        if (videosStore.activo.image)
          data = {
            ...data,
            image: videosStore.activo.image.id,
          };
        res = await saveVideosAction(
          dispatch,
          videosStore.activo.id,
          data,
          file
        );
        setLoadingSave(false);
        return;
      }
      res = await saveVideosAction(dispatch, null, data, file);
      setLoadingSave(false);
      isModal(false);
    }
  };

  useEffect(() => {
    if (videosStore.activo) {
      setTitulo(videosStore.activo.titulo);
      setTipo(videosStore.activo.tipo);
      setCategorias(videosStore.activo.categorias);
      setDesc(videosStore.activo.desc);
      setLink(videosStore.activo.link);
      setImage(
        videosStore.activo.image
          ? `${videosStore.activo.image.folder}/${videosStore.activo.image.nombre}`
          : null
      );
    }
  }, [videosStore.activo]);

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="image">Imagén de portada</Label>
            <Input
              id="image"
              name="image"
              type="file"
              onChange={(e) => {
                if (!e.target.files || e.target.files.length === 0) {
                  setFile(undefined);
                  return;
                }
                setFile(e.target.files[0]);
              }}
            />
          </FormGroup>
          <img
            src={
              image
                ? `${archivosFiles}/${image}`
                : preview
                  ? preview
                  : '../../img/movies.png'
            }
            width="50%"
          />
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="titulo">Titulo</Label>
                <Input
                  id="titulo"
                  name="titulo"
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="select-tipos">Tipo</Label>
                <Input
                  id="select-tipos"
                  name="selectMulti"
                  type="select"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  {tiposArray.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <Label for="select-categorias">Categorías</Label>
              {categoriasArray.map((item, index) => (
                <FormGroup check key={`${item.id}-${index}`}>
                  <Input
                    id="checkbox2"
                    type="checkbox"
                    checked={categorias.includes(item.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCategorias([...categorias, item.id]);
                      } else {
                        setCategorias(
                          categorias.filter((el) => el !== item.id)
                        );
                      }
                    }}
                  />{' '}
                  <Label
                    onClick={() => {
                      if (!categorias.includes(item.id)) {
                        setCategorias([...categorias, item.id]);
                      } else {
                        setCategorias(
                          categorias.filter((el) => el !== item.id)
                        );
                      }
                    }}
                    check
                  >
                    {item.label}
                  </Label>
                </FormGroup>
              ))}

              {/* <FormGroup>
                <Label for="select-categorias">Categoría</Label>
                <Input
                  id="select-categorias"
                  name="selectMulti"
                  type="select"
                  multiple
                  onChange={(e) => setCategorias(e.target.value)}
                >
                  {categoriasArray.map((item) => (
                    <option value={item.id}>{item.label}</option>
                  ))}
                </Input>
              </FormGroup> */}

              <FormGroup>
                <Label for="desc">Descripción</Label>
                <Input
                  id="desc"
                  name="descripcion"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="link">Link de video</Label>
                <Input
                  id="link"
                  name="link"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="success" onClick={handlerSubmit}>
            {loadingSave ? (
              <>
                <Spinner size="sm" />
                &nbsp;&nbsp;Guardando registro...
              </>
            ) : (
              'GUARDAR'
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormVideos;
