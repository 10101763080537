import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import styled from 'styled-components';
import { setActivoVideosAction } from '../../../store/actions';
import { SET_VIDEOS_ACTIVO } from '../../../store/types';
import FormVideos from './components/FormVideos';
import Listado from './components/lista';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const VideosAdmin = () => {
  const dispatch = useDispatch();
  const videosState = useSelector((state) => state.videos);

  const [isModal, setIsModal] = useState();
  const toggle = async () => {
    setIsModal(!isModal);
    if (isModal) await setActivoVideosAction(dispatch, null);
  };

  useEffect(() => {
    if (videosState.activo) setIsModal(true);
  }, [videosState.activo]);

  return (
    <Container>
      <h2>Videos publicitarios</h2>
      <Row>
        <Col md={4}>
          <Button
            color="success"
            onClick={() => {
              dispatch({
                type: SET_VIDEOS_ACTIVO,
                payload: null,
              });
              setIsModal(true);
            }}
          >
            Nuevo
          </Button>
        </Col>
      </Row>
      <Listado />
      <Modal
        isOpen={isModal}
        toggle={toggle}
        centered
        style={{ maxWidth: '80%' }}
      >
        <ModalHeader toggle={toggle}>
          {videosState.activo ? 'Editar' : 'Nueva'} video publicitario
        </ModalHeader>
        <ModalBody>
          <FormVideos isModal={setIsModal} />
        </ModalBody>
      </Modal>
    </Container>
  );
};
