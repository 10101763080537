import React, { useEffect, useState } from 'react';
import { tada } from 'react-animations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Modal, TabContent, TabPane } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import { MIS_VIDEOS_CINE } from '../../../common/const';
import { SET_VIDEO_CINE } from '../../../store/types';
import { device } from '../../../utils/constants';
import LaCaja from '../landing-page/lacaja/lacaja';
import { Body, Section } from '../nosotros/components';
import LayoutNosotros from '../nosotros/layoutNosotros';
import {
  AlignB,
  BodyFotoMin,
  BodyTabs,
  BodyTexto,
  BotonSiguiente,
  BotonSiguienteFlecha,
  BotonTabs,
  Descripcion,
  FotoMax,
  FotoMaxInfo,
  FotoMin,
  SectionForm,
  TituloTexto,
} from './components';

const pulseAnimation = keyframes`${tada}`;

export const CardPulse = styled.div`
  animation: 5s ${pulseAnimation} infinite;
`;

const PagePublicidad = ({ isVideosNew = false }) => {
  const { t } = useTranslation();

  const array = [
    {
      id: 0,
      titulo: 'LA VIRGEN NEGRA',
      img: './img/lavirgennegra.jpg',
      desc: t('cine.virgen.desc'),
      ficha: {
        director: 'Ignacio Castillo Cottin',
        writers: 'Ignacio Castillo Cottin',
        executive: 'Ignacio Castillo Cottin, Nathalie Sar-Shalom',
        composer: 'Elik Alvarez',
        cinematographer: 'Cezary Jaworski',
        editor: 'Danielle Fillios',
        casting:
          'Carmen Maura, Angélica Aragón, Matheus Nachtergaeele y Carolina Torres',
      },
      ano: '',
      duracion: '',
      nota: false,
      trailer: true,
      fullMovie:
        'https://media.publit.io/file/Cine/la_virgen_negra_-versi-n_biennale_2022-1080p.html?player=public01&at=eyJpdiI6Ind0YzB0am15N1k0M0k1Zkg4MzB5Rmc9PSIsInZhbHVlIjoidk1RaE12SVExNEpDTGw5MlF6a3FxNnhrR0RCNHFta2FobjUrSmd3UU9Mdz0iLCJtYWMiOiI0ZjVmZDgwOTYyYWQzNjRmMTU0OGU3YzIwNjBiNjU5MzUyMDM4Yzc5NzIxZjY1NjgyZDczMjRmMGY0MGM2ZmE5IiwidGFnIjoiIn0=',
    },
    {
      id: 1,
      titulo: 'EL INCA',
      img: './img/inca2cen.jpg',
      desc: t('cine.inca.desc'),
      ficha: {
        director: 'Ignacio Castillo Cottin',
        writers: 'Ignacio Castillo Cottin, Ada Hernández',
        executive: 'Ignacio Castillo Cottin, Nathalie Sar-Shalom',
        composer: 'Luis Miguel Emmanuelli',
        cinematographer: 'Alexandra Henao',
        editor: 'Maikel Jimenez',
        casting:
          'Alexander Leterni, Scarlett Jaimes, Miguel Ferrari, Daniela Bueno.',
      },
      ano: '',
      duracion: '',
      nota: false,
      trailer: true,
      fullMovie:
        'https://media.publit.io/file/Cine/el_inca_-_for_your_consideration-1080p.html?player=public01&at=eyJpdiI6IjhESVVRcERVTnJyOHZyMjNaVUYrOGc9PSIsInZhbHVlIjoiWkxCcHNpL0huVGp6T0kwVXBGTk5WekFjSE82VHdqVkJvVjRSajVTZkgzcz0iLCJtYWMiOiJiOWFmNTk0M2U2MjlkMTE3YzI1Mjc0NDE1MjhjNDc4NzJlZTE3ZDQ1ODRjZmZmYjBjODVjMzA3ZTk0OTk0ZjAzIiwidGFnIjoiIn0=',
    },
    {
      id: 2,
      titulo: 'LA DANUBIO',
      img: './img/danubio.jpg',
      desc: t('cine.danubio.desc'),
      ficha: {
        director: 'Ignacio Castillo Cottin',
        writers: '',
        executive: 'Ignacio Castillo Cottin, Reinaldo Ortiz',
        composer: 'Luis Miguel Emmanuelli',
        cinematographer: 'Gerard Uzcategui',
        editor: 'Laura Portela',
        casting: '',
      },
      ano: '',
      duracion: '',
      nota: false,
      trailer: true,
      fullMovie:
        'https://media.publit.io/file/Cine/la_danubio_-_esp-1080p.html?player=public01&at=eyJpdiI6ImlKbXV0Tm80Y2pBWFErOVNvWFBTNmc9PSIsInZhbHVlIjoicVQzdlY5MEI2ZmJYckJOUTBqcW4wamFXdDhOdC9Vcm84aUFoMzVMcmh3MD0iLCJtYWMiOiJjMjI1MWFkZTcxOWJhOWJjODE3NGE1Zjg2OGI5NDIxODU1ZjczYjA5YmVlMjI1MDJiZTlmOTMyMmE4NGU4MTdiIiwidGFnIjoiIn0=',
      fullMovieEn:
        'https://media.publit.io/file/Cine/la_danubio_-_eng-1080p.html?player=public01&at=eyJpdiI6Im5WSjF3WkcwYlBRNVYwMTZ6RE84WVE9PSIsInZhbHVlIjoiK2FMK3NYdFMrbnQ0U2ZMRUlrenpuYTlGb0drOVBvbFZjdHgvdFpuUnFhZz0iLCJtYWMiOiI0NThlYTNiYjZiYTk1YzVlYzVmNGZlY2ZkNWVkNTJmM2NmYTQ5YTA3ZDQxMjNhMGFlMzcwM2JmNGFhMGEzOTU4IiwidGFnIjoiIn0=',
    },
  ];

  const dispatch = useDispatch();

  const codigosState = useSelector((state) => state.codigos);
  const layoutState = useSelector((state) => state.layout);

  const [activo, setActivo] = useState(array[0]);
  const [isTrailer, setIsTrailer] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [isFichatecnica, setIsFichatecnica] = useState(false);
  const [isNotaprensa, setIsNotaprensa] = useState(false);

  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState('');

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);

    if (codigosState.activoCine) {
      const x = localStorage.getItem(MIS_VIDEOS_CINE);
      dispatch({
        type: SET_VIDEO_CINE,
        payload: x === 'true',
      });
    } else {
      dispatch({
        type: SET_VIDEO_CINE,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (codigosState.activoCine) {
      const x = localStorage.getItem(MIS_VIDEOS_CINE);
      dispatch({
        type: SET_VIDEO_CINE,
        payload: x === 'true',
      });
    } else {
      dispatch({
        type: SET_VIDEO_CINE,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigosState.activoCine]);

  const [activeTab, setActiveTab] = useState('1');

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  const BotonSiguienteCompon = (
    <BotonSiguienteFlecha
      fondo
      onClick={() => {
        let index = activo.id === 0 ? 1 : activo.id === 1 ? 2 : 0;
        setActivo(array[index]);
        setActiveTab('1');

        if (isMobileDevice) {
          setIsInfo(false);
          setIsFichatecnica(false);
          setIsNotaprensa(false);
          setIsTrailer(false);
        }
      }}
    >
      {t('cine.next')} <label>{'>'}</label>
    </BotonSiguienteFlecha>
  );

  const fichaTecnica = (
    <div className="bodytecto">
      <label className="titulo">{t('cine.director')}</label>
      <label className="info">{activo.ficha.director}</label>
      {activo.ficha.writers === '' ? (
        <></>
      ) : (
        <label className="titulo">{t('cine.guion')}</label>
      )}
      {activo.ficha.writers === '' ? (
        <></>
      ) : (
        <label className="info">{activo.ficha.writers}</label>
      )}
      <label className="titulo">{t('cine.prod')}</label>
      <label className="info">{activo.ficha.executive}</label>
      <label className="titulo">{t('cine.compo')}</label>
      <label className="info">{activo.ficha.composer}</label>
      <label className="titulo">{t('cine.foto')}</label>
      <label className="info">{activo.ficha.cinematographer}</label>
      <label className="titulo">{t('cine.edicion')}</label>
      <label className="info">{activo.ficha.editor}</label>
    </div>
  );

  const lacaja = layoutState.isVideoCine && (
    <SectionForm>
      <LaCaja codes={true} isPropaganda={false} isRoute={false} />
    </SectionForm>
  );

  const body = (
    <>
      {isMobileDevice ? (
        <>
          <Section h={'auto'} m={'4vw 0vw'}>
            <BodyFotoMin>
              {array.map((el) => (
                <FotoMin
                  activo={activo.id === el.id}
                  onClick={() => {
                    setActivo(el);
                    setActiveTab('1');
                    if (isMobileDevice) {
                      setIsInfo(false);
                    }
                  }}
                  img={el.img}
                />
              ))}
            </BodyFotoMin>
            <FotoMax
              img={activo.img}
              onClick={() => {
                setIsInfo(true);
              }}
            >
              {!isInfo ? (
                <></>
              ) : (
                <FotoMaxInfo>
                  <TituloTexto>{activo.titulo}</TituloTexto>
                  <table>
                    <tr>
                      <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                      <td>
                        <BotonSiguiente
                          fondo
                          onClick={() => {
                            setIsFichatecnica(!isFichatecnica);
                            setIsNotaprensa(false);
                            setIsTrailer(false);
                          }}
                        >
                          {t('cine.ficha')}
                        </BotonSiguiente>
                      </td>
                    </tr>
                  </table>
                  {isTrailer ? (
                    <center>
                      {activo.id === 0 ? (
                        <iframe
                          title="vimeo-player"
                          src="https://player.vimeo.com/video/150476964?h=6bca967a76"
                          width="340"
                          height="280"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      ) : (
                        <></>
                      )}
                      {activo.id === 1 ? (
                        <iframe
                          title="vimeo-player"
                          src="https://player.vimeo.com/video/184864811?h=47171ad579"
                          width="340"
                          height="280"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      ) : (
                        <></>
                      )}
                      {activo.id === 2 ? (
                        <iframe
                          title="vimeo-player"
                          src="https://player.vimeo.com/video/483320605?h=fab9d4b212"
                          width="340"
                          height="280"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      ) : (
                        <></>
                      )}
                    </center>
                  ) : isFichatecnica ? (
                    fichaTecnica
                  ) : isNotaprensa ? (
                    <TabPane tabId="3" className="textoscrol">
                      <label className="titulo">
                        DOCUMENTAL LA DANUBIO: LA RECETA DE LA VENEZUELA EXITOSA
                      </label>
                      <label className="infonota">
                        &nbsp;&nbsp;&nbsp;&nbsp;El próximo miércoles 2 de
                        diciembre los venezolanos podrán ver, a través de la
                        plataforma de Trasnocho Cultural, La Danubio; un
                        documental dirigido por Ignacio Castillo Cottin que le
                        ofrecerá a los venezolanos una inyección de optimismo
                        sobre la Venezuela pujante, resiliente y trabajadora, la
                        que no solemos voltear a ver todos los días y que tanta
                        falta hace destacar en estos momentos.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Este documental enseña cómo
                        funciona la pastelería más famosa de la capital
                        venezolana, el detrás de cámara de una empresa cuyo
                        protocolo de funcionamiento es precisamente no tener
                        uno. En la voz de sus protagonistas esta pieza nos
                        cuenta la agridulce dinámica familiar de los Kerese,
                        principalmente de Evelia y sus tres hijos - Pablo,
                        Andrés y Alejandro - que ha hecho que este negocio
                        familiar sea tan exitoso, no solo porque arriba a sus 50
                        años sino porque se ha convertido en sinónimo de
                        Caracas.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Se trata de una gran apuesta de
                        la productora Pa’ Los Panas, con la dirección del
                        cineasta venezolano Ignacio Castillo Cottin, conocido
                        por su destacado trabajo en los largometrajes El Inca
                        (2016) y La Virgen Negra (2008).
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Castillo Cottin explica que
                        inicialmente su motivación para hacer esta pieza fue
                        netamente emocional. “Vivir en Venezuela, mantenerse
                        aquí por voluntad propia, es algo particular hoy día.
                        Conseguir a gente trabajando en Venezuela, invirtiendo
                        en Venezuela, echándole pichón y visualizándose a
                        mediano o largo plazo en Venezuela es algo difícil de
                        encontrar porque en mi generación es más fácil ver a
                        quien quiere irse, que a quien quiere quedarse. Eso me
                        generó una conexión emocional con gente que está en
                        Venezuela en la misma página que yo, que está trabajando
                        en y por el país y que no se ve lejos de aquí”.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Aunque el director admite que
                        este documental podría dirigirse inicialmente a un
                        nicho, asegura que toca temas universales. “Si tienes la
                        suerte de saber lo que es Danubio de primera mano será
                        un descubrimiento, porque conocerás de verdad lo que es
                        esta pastelería y tu percepción va a cambiar
                        drásticamente. A mi me pasó. Pero también está la
                        particularidad de los personajes, una familia muy
                        auténtica; y ese será un motivo para que quienes no
                        conocen lo que es La Danubio vean el documental”.
                      </label>
                      <label className="titulo">¿POR QUÉ EN PANDEMIA?</label>
                      <label className="infonota">
                        &nbsp; &nbsp; &nbsp; &nbsp;A pesar de que 2.020 no ha
                        sido el mejor año para nadie y la industria
                        cinematográfica no ha escapado a esta situación, también
                        ha sido un año lleno de oportunidades para quien ha
                        sabido reinventarse.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Ambos escenarios impulsaron a
                        Pa’ Los Panas a “continuar con el show” y no parar el
                        estreno de “La Danubio”, sino a salir por la plataforma
                        del Trasnocho Cultural.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;“Creo que será una inyección a
                        nuestra identidad desde una perspectiva diferente, de
                        una Venezuela que no estamos viendo. El documental puede
                        servir justamente como un espaldarazo para la gente que
                        todavía está en el país y también para aquellos que no -
                        más allá de la melancolía; porque podrán ver un dibujo
                        de la Venezuela que existe, al margen de la crisis, que
                        es la que puja, que resiste y que sigue trabajando todos
                        los días con el sueño de ver un mejor futuro. Eso es
                        algo que quienes viven en Venezuela y que quienes están
                        afuera disfrutarán en un momento como éste”, explica
                        Ignacio Castillo Cottin.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;El director además explica que
                        el mes del estreno no podía ser otro. “Teníamos que
                        lanzarlo en diciembre, porque La Danubio es diciembre,
                        es el pan de jamón, era el momento. Era lanzarlo ahora o
                        el año que viene en ese mismo mes. Pero la única
                        motivación para esperar un año era poder estar en salas
                        de cine, y de eso no hay certeza”.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;La Danubio es un documental que
                        va más allá de destacar valores como el trabajo o la
                        familia, le habla al ADN del venezolano, reflejando al
                        país con una dosis de optimismo. Será un pan de jamón
                        para el alma de quienes han sufrido la pandemia y las
                        calamidades que atraviesa el venezolano hoy, adentro o
                        afuera. Y ese es el mejor motivo para verlo.
                      </label>
                    </TabPane>
                  ) : (
                    <Descripcion>{activo.desc}</Descripcion>
                  )}
                  <table>
                    <tr>
                      <td>
                        {!activo.trailer ? (
                          <>
                            {activo.id === 0 ? (
                              <BotonSiguiente
                                // fondo
                                onClick={() => {
                                  setIsNotaprensa(!isNotaprensa);
                                  setIsFichatecnica(false);
                                  setIsTrailer(false);
                                }}
                              >
                                {t('cine.press')}
                              </BotonSiguiente>
                            ) : (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </>
                            )}
                          </>
                        ) : (
                          <BotonSiguiente
                            fondo
                            onClick={() => {
                              setIsTrailer(!isTrailer);
                              setIsFichatecnica(false);
                              setIsNotaprensa(false);
                            }}
                          >
                            {t('cine.trailer')}
                          </BotonSiguiente>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </FotoMaxInfo>
              )}
            </FotoMax>
            {activo.fullMovie && layoutState.isVideoCine ? (
              <BotonSiguiente
                // fondo
                verMovie
                onClick={() => {
                  // openInNewTab('https://cinemestizo.com/es/la-virgen-negra')
                  setVideoLink(activo.fullMovie);
                  setModal(true);
                }}
              >
                {t('cine.fullmovie')}
              </BotonSiguiente>
            ) : (
              <></>
            )}
            {BotonSiguienteCompon}
          </Section>
          {lacaja}
        </>
      ) : (
        <>
          <Section
            h={isVideosNew ? '50vw' : '60vw'}
            m={isVideosNew ? '0vw' : '2vw 0vw'}
          >
            <Body
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              <FotoMax img={activo.img} />
              <BodyFotoMin>
                {array.map((el) => (
                  <FotoMin
                    activo={activo.id === el.id}
                    onClick={() => {
                      setActivo(el);
                      setActiveTab('1');
                    }}
                    img={el.img}
                  />
                ))}
              </BodyFotoMin>
              <BodyTexto>
                <TituloTexto>{activo.titulo}</TituloTexto>
                <BodyTabs>
                  <BotonTabs
                    activo={activeTab === '1'}
                    onClick={() => {
                      setActiveTab('1');
                    }}
                  >
                    {t('cine.desc')}
                  </BotonTabs>
                  <BotonTabs
                    activo={activeTab === '2'}
                    onClick={() => {
                      setActiveTab('2');
                    }}
                  >
                    {t('cine.ficha')}
                  </BotonTabs>
                </BodyTabs>
                <TabContent className="bodyTabsContent" activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Descripcion>{activo.desc}</Descripcion>
                  </TabPane>
                  <TabPane tabId="2">{fichaTecnica}</TabPane>
                  {activo.id !== 0 ? (
                    <></>
                  ) : (
                    <TabPane tabId="3" className="textoscrol">
                      <label className="titulo">
                        DOCUMENTAL LA DANUBIO: LA RECETA DE LA VENEZUELA EXITOSA
                      </label>
                      <label className="infonota">
                        &nbsp;&nbsp;&nbsp;&nbsp;El próximo miércoles 2 de
                        diciembre los venezolanos podrán ver, a través de la
                        plataforma de Trasnocho Cultural, La Danubio; un
                        documental dirigido por Ignacio Castillo Cottin que le
                        ofrecerá a los venezolanos una inyección de optimismo
                        sobre la Venezuela pujante, resiliente y trabajadora, la
                        que no solemos voltear a ver todos los días y que tanta
                        falta hace destacar en estos momentos.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Este documental enseña cómo
                        funciona la pastelería más famosa de la capital
                        venezolana, el detrás de cámara de una empresa cuyo
                        protocolo de funcionamiento es precisamente no tener
                        uno. En la voz de sus protagonistas esta pieza nos
                        cuenta la agridulce dinámica familiar de los Kerese,
                        principalmente de Evelia y sus tres hijos - Pablo,
                        Andrés y Alejandro - que ha hecho que este negocio
                        familiar sea tan exitoso, no solo porque arriba a sus 50
                        años sino porque se ha convertido en sinónimo de
                        Caracas.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Se trata de una gran apuesta de
                        la productora Pa’ Los Panas, con la dirección del
                        cineasta venezolano Ignacio Castillo Cottin, conocido
                        por su destacado trabajo en los largometrajes El Inca
                        (2016) y La Virgen Negra (2008).
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Castillo Cottin explica que
                        inicialmente su motivación para hacer esta pieza fue
                        netamente emocional. “Vivir en Venezuela, mantenerse
                        aquí por voluntad propia, es algo particular hoy día.
                        Conseguir a gente trabajando en Venezuela, invirtiendo
                        en Venezuela, echándole pichón y visualizándose a
                        mediano o largo plazo en Venezuela es algo difícil de
                        encontrar porque en mi generación es más fácil ver a
                        quien quiere irse, que a quien quiere quedarse. Eso me
                        generó una conexión emocional con gente que está en
                        Venezuela en la misma página que yo, que está trabajando
                        en y por el país y que no se ve lejos de aquí”.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Aunque el director admite que
                        este documental podría dirigirse inicialmente a un
                        nicho, asegura que toca temas universales. “Si tienes la
                        suerte de saber lo que es Danubio de primera mano será
                        un descubrimiento, porque conocerás de verdad lo que es
                        esta pastelería y tu percepción va a cambiar
                        drásticamente. A mi me pasó. Pero también está la
                        particularidad de los personajes, una familia muy
                        auténtica; y ese será un motivo para que quienes no
                        conocen lo que es La Danubio vean el documental”.
                      </label>
                      <label className="titulo">¿POR QUÉ EN PANDEMIA?</label>
                      <label className="infonota">
                        &nbsp; &nbsp; &nbsp; &nbsp;A pesar de que 2.020 no ha
                        sido el mejor año para nadie y la industria
                        cinematográfica no ha escapado a esta situación, también
                        ha sido un año lleno de oportunidades para quien ha
                        sabido reinventarse.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;Ambos escenarios impulsaron a
                        Pa’ Los Panas a “continuar con el show” y no parar el
                        estreno de “La Danubio”, sino a salir por la plataforma
                        del Trasnocho Cultural.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;“Creo que será una inyección a
                        nuestra identidad desde una perspectiva diferente, de
                        una Venezuela que no estamos viendo. El documental puede
                        servir justamente como un espaldarazo para la gente que
                        todavía está en el país y también para aquellos que no -
                        más allá de la melancolía; porque podrán ver un dibujo
                        de la Venezuela que existe, al margen de la crisis, que
                        es la que puja, que resiste y que sigue trabajando todos
                        los días con el sueño de ver un mejor futuro. Eso es
                        algo que quienes viven en Venezuela y que quienes están
                        afuera disfrutarán en un momento como éste”, explica
                        Ignacio Castillo Cottin.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;El director además explica que
                        el mes del estreno no podía ser otro. “Teníamos que
                        lanzarlo en diciembre, porque La Danubio es diciembre,
                        es el pan de jamón, era el momento. Era lanzarlo ahora o
                        el año que viene en ese mismo mes. Pero la única
                        motivación para esperar un año era poder estar en salas
                        de cine, y de eso no hay certeza”.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;La Danubio es un documental que
                        va más allá de destacar valores como el trabajo o la
                        familia, le habla al ADN del venezolano, reflejando al
                        país con una dosis de optimismo. Será un pan de jamón
                        para el alma de quienes han sufrido la pandemia y las
                        calamidades que atraviesa el venezolano hoy, adentro o
                        afuera. Y ese es el mejor motivo para verlo.
                      </label>
                    </TabPane>
                  )}
                  <TabPane tabId="4">
                    <center>
                      {activo.id === 0 ? (
                        <iframe
                          title="vimeo-player"
                          src="https://player.vimeo.com/video/150476964?h=6bca967a76"
                          width="440"
                          height="332"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      ) : (
                        <></>
                      )}
                      {activo.id === 1 ? (
                        <iframe
                          title="vimeo-player"
                          src="https://player.vimeo.com/video/184864811?h=47171ad579"
                          width="440"
                          height="360"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      ) : (
                        <></>
                      )}
                      {activo.id === 2 ? (
                        <iframe
                          title="vimeo-player"
                          src="https://player.vimeo.com/video/483320605?h=fab9d4b212"
                          width="440"
                          height="332"
                          frameborder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      ) : (
                        <></>
                      )}
                    </center>
                  </TabPane>
                </TabContent>
                <AlignB>
                  {activo.nota ? (
                    <BotonTabs
                      activo={activeTab === '3'}
                      onClick={() => {
                        setActiveTab('3');
                      }}
                    >
                      {t('cine.press')}
                    </BotonTabs>
                  ) : (
                    <></>
                  )}
                  {!activo.trailer ? (
                    <>
                      <label></label>
                    </>
                  ) : (
                    <BotonTabs
                      activo={activeTab === '4'}
                      onClick={() => {
                        setActiveTab('4');
                      }}
                    >
                      {t('cine.trailer')}
                    </BotonTabs>
                  )}
                  {activo.fullMovie && layoutState.isVideoCine ? (
                    <BotonSiguiente
                      // fondo
                      verMovie
                      fs="1.5vw"
                      onClick={() => {
                        // openInNewTab('https://cinemestizo.com/es/la-virgen-negra')
                        setVideoLink(activo.fullMovie);
                        setModal(true);
                      }}
                    >
                      {t('cine.fullmovie')}
                    </BotonSiguiente>
                  ) : (
                    <></>
                  )}
                </AlignB>
                <center>{BotonSiguienteCompon}</center>
              </BodyTexto>
            </Body>
          </Section>
          {lacaja}
        </>
      )}
      {/* <BotonLang /> */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="lg"
        className="modal-blog-video"
      >
        <center>
          <figure
            style={{
              left: 0,
              width: '100%',
              height: 0,
              position: 'relative',
              paddingBottom: '56.25%',
              marginBlockEnd: 0,
              marginBlockStart: 0,
              marginInlineStart: 0,
              marginInlineEnd: 0,
            }}
          >
            <iframe
              title="vimeo-player"
              id="pv_mMawGz38"
              src={videoLink}
              scrolling="no"
              style={{
                border: 0,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
                overflow: 'hidden',
              }}
              allowFullScreen="false"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            />
          </figure>
        </center>
      </Modal>
    </>
  );

  if (isVideosNew) {
    return body;
  }

  return (
    <LayoutNosotros
      title={t('publicidad.cine')}
      subTitle={t('cine.text')}
      isWhiteLang
      isSmooth
    >
      {body}
    </LayoutNosotros>
  );
};

export default PagePublicidad;
