// export const serverUrl = 'http://localhost';
// export const serverUrl = 'https://palospanas.com';
// export const baseUrl = `${serverUrl}:3000/api/v1`;

export const serverUrl = 'https://dev.palospanas.com';
export const baseUrl = `${serverUrl}:3005/api/v1`;

// export const serverUrl = 'http://157.245.244.236';
// export const baseUrl = `${serverUrl}:3005/api/v1`;

export const login = `${baseUrl}/auth/login`;
export const clientes = `${baseUrl}/clientes`;
export const peliculas = `${baseUrl}/peliculas`;
export const videos = `${baseUrl}/videos`;
export const archivos = `${baseUrl}/archivos`;
export const archivosFiles = `${archivos}/files`;
export const blog = `${baseUrl}/blog`;
export const blogPublico = `${baseUrl}/blog/publico`;
export const codigos = `${baseUrl}/codigos`;
export const suscripciones = `${baseUrl}/suscripciones`;
export const newsletter = `${baseUrl}/newsletter`;
export const newsletterPublico = `${baseUrl}/newsletter/publico`;
export const tiposBoletin = `${baseUrl}/tipo_boletin`;

export const Token = ({ isToken = true }) => {
  const token = localStorage.getItem('PLPtoken');
  const headers = {
    'Content-Type': 'application/json',
  };
  if (isToken) headers['Authorization'] = `Bearer ${token}`;
  return headers;
};
