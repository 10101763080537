import React from 'react';
import { slideInDown } from 'react-animations';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled, { keyframes } from 'styled-components';
import { device } from '../../../../utils/constants';
import useMenuHook from './MenuHook';

const slideInUpAimation = keyframes`${slideInDown}`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 2vw;
  right: 2vw;

  animation: 1s ${slideInUpAimation};

  @media ${device.mobile} {
    top: 1em;
    right: 1em;
  }
`;

const ButtonIcon = styled.img`
  width: ${({ wd }) => wd ?? '3.4vw'};
  height: ${({ hp }) => hp ?? '1.9vw'};
  cursor: pointer;

  /* background: url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  transition: background 0.5s ease; */

  @media ${device.mobile} {
    width: ${({ wd }) => wd ?? '12vw'};
    height: ${({ hp }) => hp ?? '1.8em'};
  }
`;

const ButtonLang = styled.div`
  font-size: 1.5vw;
  font-weight: w600;
  color: ${({ menuBlack }) => (!menuBlack ? 'white' : 'black')};
  cursor: pointer;
  font-family: 'MontserratLight';

  @media ${device.mobile} {
    font-size: 1.3em;
  }
`;

const Menu = ({ menuBlack = false }) => {
  const landingStore = useSelector((state) => state.landing);

  const { lang, changeLang, hanlderOpenMenu, openMenu } = useMenuHook();
  const mobile = useMediaQuery({ query: device.mobile });

  return (
    <Container>
      <ButtonIcon
        onClick={() => hanlderOpenMenu(!openMenu)}
        menuBlack={menuBlack}
        wd={openMenu ? (mobile ? '3em' : '3.1vw') : null}
        hp={openMenu ? (mobile ? '1.4em' : '1.6vw') : null}
        src={`img/${
          landingStore.openMenu
            ? menuBlack
              ? 'closeBlack.png'
              : 'closeWhite.png'
            : menuBlack
              ? 'menuBlack.png'
              : 'menuWhite.png'
        }`}
      />
      <ButtonLang
        alt="Cambio de Idioma"
        white={true}
        menuBlack={menuBlack}
        onClick={() => {
          changeLang(lang === 'en' ? 'es' : 'en');
          window.location.reload();
        }}
      >
        {lang === 'en' ? 'ENG' : 'ESP'}
      </ButtonLang>
    </Container>
  );
};

export default Menu;
