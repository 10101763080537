import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { blogReducer } from './blog.reducer';
import { clientesReducer } from './clientes.reducer';
import { codigosReducer } from './codigos.reducer';
import { landingReducer } from './landing.reducer';
import { layoutReducer } from './layout.reducer';
import { newsletterReducer } from './newsletter.reducer';
import { peliculasReducer } from './peliculas.reducer';
import { suscripcionesReducer } from './suscripciones.reducer';
import { tiposBoletinReducer } from './tipos_boletin.reducer';
import { usersReducer } from './users.reducer';
import { videosReducer } from './videos.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  clientes: clientesReducer,
  layout: layoutReducer,
  users: usersReducer,
  peliculas: peliculasReducer,
  videos: videosReducer,
  codigos: codigosReducer,
  blog: blogReducer,
  newsletter: newsletterReducer,
  suscripciones: suscripcionesReducer,
  landing: landingReducer,
  tiposBoletin: tiposBoletinReducer,
});

export default rootReducer;
