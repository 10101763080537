import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import { archivosFiles } from '../../../../services/api';
import {
  deleteVideosAction,
  getAllVideosAction,
  setActivoVideosAction,
} from '../../../../store/actions';
import { GET_CLIENTES } from '../../../../store/types';
import useVideosHook from '../../../web/landing-news/portafolio/VideosLinksHook';

const Container = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;

  .botones {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;
const Tag = styled.label`
  font-size: ${(props) => props.size ?? '8pt'};
  color: ${(props) => (props.leido ? 'green' : 'red')};
  margin: 0 10px;
  cursor: pointer;
`;

const Nombre = styled.div`
  cursor: pointer;
  label {
    cursor: pointer;
  }
`;

const Links = styled.div`
  display: flex;

  a {
    &:hover {
      font-size: 1.04vw;
    }
  }

  .wasa {
    margin-left: 1vw;
  }
`;

const Iconos = styled.img`
  width: 1.5vw;
  height: ${(props) => props.h};
  cursor: pointer;
  margin-bottom: 0.7vw;

  &:hover {
    transform: scale(1.2);
  }
`;

const ListadoVideos = () => {
  const dispatch = useDispatch();
  const videosState = useSelector((state) => state.videos);
  const categoriasArray = useVideosHook().categoriasArray;

  const [loadingRefresh, setLoadingRefresh] = useState();
  const [loadingData, setLoadingData] = useState();

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState();
  const toggle = () => setIsModalDelete(false);

  const getAllVideos = async () => {
    await getAllVideosAction(dispatch);
    setLoadingRefresh(false);
  };

  useEffect(() => {
    getAllVideos();
  }, []);

  const columns = [
    {
      name: 'ID',
      width: '60px',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Foto',
      selector: (row) => row.image,
      sortable: true,
      width: '80px',
      cell: (row) => (
        <div
          style={{
            width: '50px',
            height: '50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingBottom: '7px',
          }}
        >
          <img
            width="50px"
            height="50px"
            src={
              row.image
                ? `${archivosFiles}/${row.image.folder}/${row.image.nombre}`
                : '../../img/movies.png'
            }
          />
        </div>
      ),
    },
    {
      name: 'Titulo',
      width: '250px',
      sortable: true,
      selector: (row) => row.titulo,
    },
    {
      name: 'Tipo',
      sortable: true,
      selector: (row) => row.tipo,
    },
    {
      name: 'Categorias',
      sortable: true,
      cell: (row) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {row.categorias
              .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
              .map((el) => {
                const item = categoriasArray.find((cat) => cat.id === el);
                return <label key={item.id}>{item.label}</label>;
              })}
          </div>
        );
      },
    },
    {
      name: 'Descripción',
      width: '150px',
      selector: (row) => row.desc,
    },
    {
      name: 'Links',
      width: '150px',
      selector: (row) => row.link,
      sortable: true,
    },
    {
      name: '',
      width: '120px',
      cell: (row) => (
        <div>
          <Button
            size="sm"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={async () => {
              await setActivoVideosAction(dispatch, row);
            }}
          >
            <FaEdit />
          </Button>
          <Button
            size="sm"
            color="danger"
            onClick={() => {
              setDataDelete(row);
              setIsModalDelete(true);
            }}
          >
            <FaTrashAlt />
          </Button>
        </div>
      ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const fetchUsers = async (page) => {
    setLoadingData(true);
    const res = await getAllVideosAction(page, videosState.meta.itemsPerPage);
    dispatch({
      type: GET_CLIENTES,
      payload: res,
    });
    setLoadingData(false);
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoadingData(true);
    const res = await getAllVideosAction(page, newPerPage);
    dispatch({
      type: GET_CLIENTES,
      payload: res,
    });
    setLoadingData(false);
  };

  return (
    <>
      <Container>
        <div className="botones">
          <Button
            disabed={loadingRefresh}
            onClick={() => {
              setLoadingRefresh(true);
              getAllVideos();
            }}
          >
            {loadingRefresh ? (
              <>
                <Spinner size="sm" />
                &nbsp;&nbsp;Actualizando lista...
              </>
            ) : (
              'Actualizar'
            )}
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={videosState.all}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          dense
          striped
          pointerOnHover
          highlightOnHover
          onRowClicked={async (row, event) => {
            await setActivoVideosAction(dispatch, row);
          }}
          paginationPerPage={
            videosState.meta !== null && videosState.meta !== undefined
              ? videosState.meta.itemsPerPage
              : 50
          }
          paginationRowsPerPageOptions={[10, 30, 50]}
          paginationTotalRows={
            videosState.meta !== null && videosState.meta !== undefined
              ? videosState.meta.totalItems
              : 0
          }
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationServer
          progressPending={loadingData}
        />
      </Container>
      <Modal isOpen={isModalDelete} toggle={toggle}>
        <ModalHeader toggle={toggle}>Eliminar</ModalHeader>
        <ModalBody>
          ¿Seguro que deseas eliminar esta película?
          <h3>{dataDelete ? dataDelete.titulo : ''}</h3>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={async () => {
              await deleteVideosAction(dispatch, dataDelete.id);
              toggle();
            }}
          >
            Eliminar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ListadoVideos;
