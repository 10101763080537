export const LANDING_PAGE = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const CINE = '/cine';
export const PUBLICIDAD = '/publicidad';

export const PROPAGANDA = '/propaganda';
export const GOBIERNO = '/propaganda/gobierno-interino';
export const MUD = '/propaganda/mud-2021';
export const PLAN = '/propaganda/plan-pais';
export const SPOTS = '/propaganda/spots';

export const BLOG = '/blog';
export const APLICA = '/aplica';
export const ADMIN = '/admin';

// Nuevo landing page
export const NOSOTROS = '/nosotros';
export const SERVICIOS = '/servicios';
export const PORTAFOLIO = '/portafolio';
export const NEWSLETTER = '/newsletter';
export const PRODUCCION = '/produccion';
export const CONTACTO = '/contacto';
