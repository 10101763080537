export const infoHeader = [
  {
    id: 1,
    title: 'NEWSLETTER',
    subtitle: '2023',
    foto: 'img/foto.png',
    titleTop: "PA' LOS PANAS",
    titleBottom: 'EN GERUNDIO',
    footerLetfEN: 'IGNACIO CASTILLO COTTIN',
    footerLetfES: 'IGNACIO CASTILLO COTTIN',
    footerRigthEN: 'CEO | EXECUTIVE PRODUCER',
    footerRigthES: 'CEO | PRODUCTOR EJECUTIVO',
    notaEN: null,
    notaES: null,
    textoEN: [
      'En PLP sigue el proceso de transformación. Donde sumamos más gente y retos, y en ese reajuste constante trabajamos en lo que nos gusta y sabemos hacer. El 2022 fue eso; ajustar y prepararnos no tanto para este año sino más bien para los que vienen; convertidos en campañas, productos, películas, decretos y sobretodo, eventualidades. Hoy vivimos el día a día a base de ideas, proyectos y rodajes más que en horas o semanas. El contenido sigue siendo nuestro foco y convertirlo en producto útil para nuestras marcas y nuestra imagen (todavía) es el gran reto. 2023 empieza con las ganas de seguir creciendo desde las marcas que llevamos y, empujando nuestros proyectos editoriales atendiendo las necesidades de un colectivo que cada día es más unido y heterogéneo. Y en eso estamos y seguimos; uniéndonos, afinándonos, en constante movimiento y permanente agitación.',
    ],
    textoES: [
      'En PLP sigue el proceso de transformación. Donde sumamos más gente y retos, y en ese reajuste constante trabajamos en lo que nos gusta y sabemos hacer. El 2022 fue eso; ajustar y prepararnos no tanto para este año sino más bien para los que vienen; convertidos en campañas, productos, películas, decretos y sobretodo, eventualidades. Hoy vivimos el día a día a base de ideas, proyectos y rodajes más que en horas o semanas. El contenido sigue siendo nuestro foco y convertirlo en producto útil para nuestras marcas y nuestra imagen (todavía) es el gran reto. 2023 empieza con las ganas de seguir creciendo desde las marcas que llevamos y, empujando nuestros proyectos editoriales atendiendo las necesidades de un colectivo que cada día es más unido y heterogéneo. Y en eso estamos y seguimos; uniéndonos, afinándonos, en constante movimiento y permanente agitación.',
    ],
  },
  {
    id: 2,
    title: 'NEWSLETTER #2',
    subtitle: 'MAYO-AGOSTO 2023',
    foto: 'img/fotoServir.jpg',
    titleTop: 'PARA SERVIR,',
    titleBottom: 'SERVIR',
    footerLetfEN: 'IGNACIO CASTILLO COTTIN',
    footerLetfES: 'IGNACIO CASTILLO COTTIN',
    footerRigthEN: 'CEO | EXECUTIVE PRODUCER',
    footerRigthES: 'CEO | PRODUCTOR EJECUTIVO',
    notaEN: null,
    notaES: null,
    textoEN: [
      'La inspiración va de la mano de muchas cosas que suceden, y en un punto poco controlamos. Generamos situaciones, estimulamos acciones; pero al final es poco lo que podemos centrar o confiar en ella (digo en la inspiración). En cambio, hay otro elemento que sí labramos con ahínco, produce resultados; siempre buenos, o que al menos se sienten honestos; y de eso quizá se trata la vida en general: lo que poco controlamos sin perder foco en lo que queremos, lo que sea que eso signifique.',
      'La base es trabajo y lo que produce ese esfuerzo es la disposición a servir. A estar ahí; al teléfono, en la locación, en una mesa creativa, en un ascensor pichando, en la entrada de un quirófano negociando métodos de pago, en un estudio fotográfico pegando días, ajustando un presupuesto por octava vez, a las 4am en la calle produciendo una carrera que no vas a correr, sirviendo. Atento al outcome sin desmeritar el sacrificio; pero entendiendo que es poco importante si el trabajo no sale bien, sirviendo.',
      'De abril a agosto no hemos parado, hemos servido. Y si hoy tenemos que definirnos, seguimos siendo una agencia de comunicaciones integral y una casa productora audiovisual; pero la verdad, lo que realmente somos y hacemos es servir. Y yo, contento y orgulloso, con un XO en la mano les puedo decir que PLP sirve.',
    ],
    textoES: [
      'La inspiración va de la mano de muchas cosas que suceden, y en un punto poco controlamos. Generamos situaciones, estimulamos acciones; pero al final es poco lo que podemos centrar o confiar en ella (digo en la inspiración). En cambio, hay otro elemento que sí labramos con ahínco, produce resultados; siempre buenos, o que al menos se sienten honestos; y de eso quizá se trata la vida en general: lo que poco controlamos sin perder foco en lo que queremos, lo que sea que eso signifique.',
      'La base es trabajo y lo que produce ese esfuerzo es la disposición a servir. A estar ahí; al teléfono, en la locación, en una mesa creativa, en un ascensor pichando, en la entrada de un quirófano negociando métodos de pago, en un estudio fotográfico pegando días, ajustando un presupuesto por octava vez, a las 4am en la calle produciendo una carrera que no vas a correr, sirviendo. Atento al outcome sin desmeritar el sacrificio; pero entendiendo que es poco importante si el trabajo no sale bien, sirviendo.',
      'De abril a agosto no hemos parado, hemos servido. Y si hoy tenemos que definirnos, seguimos siendo una agencia de comunicaciones integral y una casa productora audiovisual; pero la verdad, lo que realmente somos y hacemos es servir. Y yo, contento y orgulloso, con un XO en la mano les puedo decir que PLP sirve.',
    ],
  },
  {
    id: 3,
    title: 'NEWSLETTER #3',
    subtitle: 'SEPTIEMBRE-DICIEMBRE 2023',
    foto: 'img/fotoServir.jpg',
    titleTop: 'MEJOR 2031',
    footerLetfEN: 'IGNACIO CASTILLO COTTIN',
    footerLetfES: 'IGNACIO CASTILLO COTTIN',
    footerRigthEN: 'CEO | EXECUTIVE PRODUCER',
    footerRigthES: 'CEO | PRODUCTOR EJECUTIVO',
    notaEN:
      'Desarrollemos ya la idea para filmar al menos dos escenas antes de que se acabe el año, si no sale el pago este viernes seguro el viernes de arriba, mándame la propuesta y ponemos un Googlemeet en la mañana, yo desde Guacuco me conecto chola y te doy feedback.',
    notaES:
      'Desarrollemos ya la idea para filmar al menos dos escenas antes de que se acabe el año, si no sale el pago este viernes seguro el viernes de arriba, mándame la propuesta y ponemos un Googlemeet en la mañana, yo desde Guacuco me conecto chola y te doy feedback.',
    textoEN: [
      'Diciembre no es el mes para cerrar nada. Las cosas se empiezan a cerrar antes o después, pero en diciembre ya no da tiempo. Y es que en esta época es normal fingir demencia o peor aún elucubrar planes que sabemos son difíciles que sucedan.',
      'La verdad es que los medios se fueron hace rato, Mercurio tiene tiempo retrogradando y el 25 y 1ero caen lunes, eso quiere decir que esas semanas serán aún más chatas. ¿Qué hacer? Lo sensato es reflexionar sobre el 2023. Corregir, reunir al equipo, planificar bien lo que viene y se puede hacer en el 2024. Nosotros, sin frenos, nos ponemos a soñar tanto por hacer en el 2024 que lo lógico sería cambiar de una vez el año y así dejar que la gente en PLP sueñe de verdad, durmiendo. Es lo más sano, pero ya no será este año.',
    ],
    textoES: [
      'Diciembre no es el mes para cerrar nada. Las cosas se empiezan a cerrar antes o después, pero en diciembre ya no da tiempo. Y es que en esta época es normal fingir demencia o peor aún elucubrar planes que sabemos son difíciles que sucedan.',
      'La verdad es que los medios se fueron hace rato, Mercurio tiene tiempo retrogradando y el 25 y 1ero caen lunes, eso quiere decir que esas semanas serán aún más chatas. ¿Qué hacer? Lo sensato es reflexionar sobre el 2023. Corregir, reunir al equipo, planificar bien lo que viene y se puede hacer en el 2024. Nosotros, sin frenos, nos ponemos a soñar tanto por hacer en el 2024 que lo lógico sería cambiar de una vez el año y así dejar que la gente en PLP sueñe de verdad, durmiendo. Es lo más sano, pero ya no será este año.',
    ],
  },
  {
    id: 5,
    title: 'NEWSLETTER #4',
    subtitle: 'ENERO-ABRIL 2024',
    foto: 'img/laliebre.png',
    titleTop: 'LA LIEBRE',
    footerLetfEN: 'IGNACIO CASTILLO COTTIN',
    footerLetfES: 'IGNACIO CASTILLO COTTIN',
    footerRigthEN: 'CEO | EXECUTIVE PRODUCER',
    footerRigthES: 'CEO | PRODUCTOR EJECUTIVO',
    notaEN: '',
    notaES: '',
    textoEN: [
      'En el mundo de los maratones existen los pacers, esas personas que corren a un ritmo específico y ayudan a los corredores a alcanzar sus tiempos deseados. El término liebre se lo escuché a la atleta Joselyn Brea cinco días antes del Maratón CAF. Estaba preocupada porque su liebre se había lesionado y ella decía que sin liebre le costaría correr, al menos alcanzar su tiempo deseado. Y es que los pacers (al igual que las liebres) se aseguran de ir a la velocidad ideal para que los corredores corran al ritmo que se prepararon, ni muy rápido ni muy lento, acorde al plan preestablecido y así llegar a la meta sanos y salvos y en buen tiempo.',
      'En Venezuela hoy en día es difícil conseguir referencias en el ámbito laboral. Uno toma decisiones con base al sentido común, instinto, educación y/o experiencia, pero es muy posible que de repente te des cuenta que estás yendo en dirección contraria o que sencillamente no has avanzado a la velocidad que debes (vas muy rápido o vas lento). En nuestra industria hace tiempo se fueron los pacers y la angustia de Joselyn Brea por no conseguir su liebre ha sido una permanente en los venezolanos que desde hace años apostamos al país.',
      "Al final Brea consiguió otra liebre, ganó la media maratón de CAF y batió record. Quién sabe si lo hubiese podido hacer sola, ella no estaba segura de lograrlo. En Pa' Los Panas andamos sin liebre desde hace tiempo, más bien nos hemos dado cuenta de que nuestras patas cada vez se hacen más largas. En todo caso, maratones en este país llevamos años corriendo.",
    ],
    textoES: [
      'En el mundo de los maratones existen los pacers, esas personas que corren a un ritmo específico y ayudan a los corredores a alcanzar sus tiempos deseados. El término liebre se lo escuché a la atleta Joselyn Brea cinco días antes del Maratón CAF. Estaba preocupada porque su liebre se había lesionado y ella decía que sin liebre le costaría correr, al menos alcanzar su tiempo deseado. Y es que los pacers (al igual que las liebres) se aseguran de ir a la velocidad ideal para que los corredores corran al ritmo que se prepararon, ni muy rápido ni muy lento, acorde al plan preestablecido y así llegar a la meta sanos y salvos y en buen tiempo.',
      'En Venezuela hoy en día es difícil conseguir referencias en el ámbito laboral. Uno toma decisiones con base al sentido común, instinto, educación y/o experiencia, pero es muy posible que de repente te des cuenta que estás yendo en dirección contraria o que sencillamente no has avanzado a la velocidad que debes (vas muy rápido o vas lento). En nuestra industria hace tiempo se fueron los pacers y la angustia de Joselyn Brea por no conseguir su liebre ha sido una permanente en los venezolanos que desde hace años apostamos al país.',
      "Al final Brea consiguió otra liebre, ganó la media maratón de CAF y batió record. Quién sabe si lo hubiese podido hacer sola, ella no estaba segura de lograrlo. En Pa' Los Panas andamos sin liebre desde hace tiempo, más bien nos hemos dado cuenta de que nuestras patas cada vez se hacen más largas. En todo caso, maratones en este país llevamos años corriendo.",
    ],
  },
  {
    id: 6,
    title: 'NEWSLETTER #5',
    subtitle: 'MAYO-SEPTIEMBRE 2024',
    foto: 'img/alunizaje.png',
    titleTop: '',
    footerLetfEN: '',
    footerLetfES: '',
    footerRigthEN: '',
    footerRigthES: '',
    notaEN: '',
    notaES: '',
    textoEN: [],
    textoES: [],
  },
];
