import { OPEN_MENU, SET_SECTION_LANDING } from '../types';

const initialState = {
  section: null,
  openMenu: false,
};

export const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SECTION_LANDING:
      return {
        ...state,
        section: action.payload,
      };
    case OPEN_MENU:
      return {
        ...state,
        openMenu: action.payload,
      };

    default:
      return state;
  }
};
