import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../utils/constants';

const OuterDiv = styled.div`
  width: ${({ isCategory }) => (isCategory ? '1.2em' : '1.5em')};
  border-radius: 0.7em;
  height: ${({ isCategory }) => (isCategory ? '1.2em' : '1.5em')};
  background-color: ${colors.primary};
  border: 0.1em solid white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerDiv = styled.div`
  width: ${({ isCategory }) => (isCategory ? '.7em' : '.9em')};
  height: ${({ isCategory }) => (isCategory ? '.7em' : '.9em')};
  border-radius: ${({ isCategory }) => (isCategory ? '.35em' : '0.45em')};
  background-color: white;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 1s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

const CheckendWidget = ({ active, onClick, isCategory = false }) => (
  <OuterDiv onClick={onClick} isCategory={isCategory}>
    <InnerDiv active={active} isCategory={isCategory} />
  </OuterDiv>
);

export default CheckendWidget;
