import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useVideosHook = () => {
  const { t } = useTranslation();

  const [selectedsType, setSelectedsType] = useState([]);
  const [selectedsCategories, setSelectedsCategories] = useState([]);

  // useEffect(() => {
  //   tiposArray.forEach((tipo) => {
  //     if (!selectedsType.includes(tipo.id)) {
  //       setSelectedsType((old) => [...old, tipo.id]);
  //     }
  //   });
  //   categoriasArray.forEach((categoria) => {
  //     if (!selectedsCategories.includes(categoria.id)) {
  //       setSelectedsCategories((old) => [...old, categoria.id]);
  //     }
  //   });
  // }, []);

  const tiposArray = [
    {
      id: 'cine',
      label: t('menu.cine'),
    },
    {
      id: 'publicidad',
      label: t('menu.publicidad'),
    },
    {
      id: 'propaganda',
      label: t('menu.propaganda'),
    },
  ];

  const categoriasArray = [
    {
      id: 'concepto',
      label: t('portafolio.concepto.title'),
    },
    {
      id: 'estrategia',
      label: t('portafolio.estrategia.title'),
    },
    {
      id: 'ejecucion',
      label: t('portafolio.ejecucion.title'),
    },
    {
      id: 'comunicacion',
      label: t('portafolio.comunicaciones.title'),
    },
    {
      id: 'experiencia',
      label: t('portafolio.experiencias.title'),
    },
    {
      id: 'talento',
      label: t('portafolio.talentos.title'),
    },
  ];

  // const publicidadArray = [
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'MISS VENEZUELA EN LÍDER',
  //     img: 'img/pub/new29042024-1.png',
  //     url: 'https://youtu.be/QSDnVO93RMw?si=nYuGWU185SpI55IJ',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'RON CARÚPANO XO',
  //     img: 'img/pub/new29042024-2.png',
  //     url: 'https://youtu.be/1UsE2pXo474?si=LDaTRljn69tK5VC2',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'RON CARÚPANO XO - TEASER',
  //     img: 'img/pub/new29042024-3.png',
  //     url: 'https://youtu.be/ouIn6jMvfGg?si=QgZiywjuNr59CTuk',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'MARATÓN CAF - CARACAS',
  //     img: 'img/pub/new29042024-4.png',
  //     url: 'https://youtu.be/2S3KH2ECGcc?si=95HLDRXVrJLLueT-',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'MARATÓN CAF - ANIMADORES',
  //     img: 'img/pub/new29042024-5.png',
  //     url: 'https://youtu.be/8Z3i0TGNycw?si=GddA3SpswjzE_4G5',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'MARATÓN CAF - RESUMEN COMPLETO',
  //     img: 'img/pub/new29042024-6.png',
  //     url: 'https://youtu.be/Cp5zZ5rFwv0?si=4kbxNxDqLgpnJH9K',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'DIGITEL - CUÑA NAVIDAD',
  //     img: 'img/pub/cuna_navidad.png',
  //     url: 'https://player.vimeo.com/video/809971717',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'PANTERA - LO MEJOR DE TI (VERSIÓN: LINDA)',
  //     img: 'img/pub/lo_mejor.jpg',
  //     url: 'https://player.vimeo.com/video/810622052',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'PLP - LENGUAJES (VERSIÓN: PUBLICIDAD)',
  //     img: 'img/pub/lenguajes.jpg',
  //     url: 'https://player.vimeo.com/video/817612938',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'JADU',
  //     img: 'img/pub/cuna1.jpg',
  //     url: 'https://player.vimeo.com/video/321765023',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'MOVISTAR',
  //     img: 'img/pub/cuna4.jpg',
  //     url: 'https://player.vimeo.com/video/319596935',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'FERRETOTAL',
  //     img: 'img/pub/cuna2.jpg',
  //     url: 'https://player.vimeo.com/video/318223497',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'COCA-COLA',
  //     img: 'img/pub/cuna3.jpg',
  //     url: 'https://player.vimeo.com/video/318218855',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'KRAFT',
  //     img: 'img/pub/cuna5.jpg',
  //     url: 'https://player.vimeo.com/video/333829619',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'JEEP GRAND CHEROKEE 2011 - VERSIÓN ABREBOCAS',
  //     img: 'img/pub/cuna6.jpg',
  //     url: 'https://player.vimeo.com/video/318238637?h=12002683ee',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'GRANOS PANTERA - TENEMOS UN COMPROMISO',
  //     img: 'img/pub/cuna7.jpg',
  //     url: 'https://player.vimeo.com/video/326689847?h=dc4641d207',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'PANTERA - LO MEJOR DE TI (VERSIÓN: MARÍA CAROLINA)',
  //     img: 'img/pub/cuna8.jpg',
  //     url: 'https://player.vimeo.com/video/733245903?h=5a68372a3d',
  //     tags: [],
  //   },
  //   {
  //     type: 'publicidad',
  //     category: 'concepto',
  //     title: '',
  //     desc: 'RON SANTA TERESA - VERSIÓN SURF',
  //     img: 'img/pub/cuna9.jpg',
  //     url: 'https://player.vimeo.com/video/333830845?h=8b02d7334c',
  //     tags: [],
  //   },
  // ];

  // const propagandaArray = [
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-2.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Delta-2-HD.html?player=public01&at=eyJpdiI6Iis1SDRzUmlJNGhJSE11ZW1XeVc4TEE9PSIsInZhbHVlIjoiN0drTVMvQnllWUdZK2hGTU1Zdlh2cWVLdFdNa1RnUUVxTFRYeURjWnRraz0iLCJtYWMiOiJmZmQwM2E1NzFkZTIzMTY5NjQ5MTc3MjI4YjZkYjA5YjJiOGIwNTZkZjBiY2E5NGUwM2Y1YTBiYjRjN2Y0MTU2IiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-3.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Largo-HD-2.html?player=public01&at=eyJpdiI6ImlPenRWM3FTYm0yZ3d2RVpDbHd1ZGc9PSIsInZhbHVlIjoicTcvWFZRZEVjTktXNEhvK0oyVTlWamw2bFAzVklDNHBmb3JVNjhFaTVwOD0iLCJtYWMiOiJjY2M3NTZiNGNmMTE2NGJjM2Q5OGVkNWQ0YmRkMThiMzY2MjFlNDc3NDkyMTM0ZDg5MzBiOGE1YTkyMTkxN2ViIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-5.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Contraste-Escuela-p-blica-baja.html?player=public01&at=eyJpdiI6ImNsUUUzTDZSN0dLRnI0UXRkU2YzYWc9PSIsInZhbHVlIjoiYnlRYUEybUZRU1RFK1p0MFZiQUFORGU4eXBTY1EwUmZ1dEg2b2lKSmJ2ND0iLCJtYWMiOiI4Yjc0NTEyMWU2N2Y5YzkxOWUzYjFlMmU4MDgyMTYyMTAzYWE4NzgxNzE1YWI0ZWY1ZDAzMzAxZWNlMjE5ODI0IiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-6.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Contraste-Apure-baja.html?player=public01&at=eyJpdiI6IjZtUUxkVWczK09lQjJqQnRnRmc2UHc9PSIsInZhbHVlIjoiTGZrRXRZSEtBMHVEVVNhbXNYMDhCNHBoUFY5R20zb0ZQZy92Q0RCVHBBaz0iLCJtYWMiOiI5MWFjMTk3NjJiZWM3ZTJhOTg1OGQwMTVmMjQxYzdiN2RiMjRiYmU0MTYyNmVhYzc4YzkyODhjZWI5ODE3YWFmIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-7.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/Falcon-Sucre-Vargas-Presi-HD.html?player=public01&at=eyJpdiI6IjIvQkEyMWV2eS9kb25EcmxSY2grWmc9PSIsInZhbHVlIjoiREJ0N1BFRkl2ZHlpMGZnZTkyeWFqM1laZ2JqQ3ZDVDVQVzZqd1NkK0lPdz0iLCJtYWMiOiJiNDhkYmQ4MTVkYTg5NWM0YTVlNzViMTUxM2FkZjQ0YThlNjY1YThjODJmODk3MjVhNzgzMGNiM2Y4Yjg2MDRhIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-8.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Contraste-Maiquet-a-baja.html?player=public01&at=eyJpdiI6IlN1ZzlUZzZwSEtGTG0wQ0JUSWw2YWc9PSIsInZhbHVlIjoiTVBoRUU3cytuSFlWT2pWYnRyKzdPTmd5Y1NQMFFNQTQvSkVRQVUwVUJPND0iLCJtYWMiOiIzNzZkNTMxNDRiOGQwMDk5MjZkZTU2OGZiNTYzNTI2YWQ3NWJmYTY2YWMwZTIwODc4OTIzMGUxZTk3YzhkNmFkIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-9.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Contraste-Playa-Guacuco-baja.html?player=public01&at=eyJpdiI6Ikg1aFowczgyWlZGTklwL1lWaEtSMXc9PSIsInZhbHVlIjoidW9ENkgzMlRiSFdHbmZMSXVLaXFOKzFPSE1zVWNsWllDcWhLSmFIL0Yrcz0iLCJtYWMiOiIxZjliZThjZWIwNmQyMTM5MDEwOGJjNTBmMjIyNTI0MTQ2YmYzZDc4OWE2NDA3NzhhYjAyYTQ0ODFiMzFiODI4IiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-10.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Guarico-Presi-HD.html?player=public01&at=eyJpdiI6Im45MlZmSXppTUVYZHVjYnJ0ZGF3bUE9PSIsInZhbHVlIjoiMTNNY2FJdllBVW1PTGZ4NmVpQkw1c3BuY2poNW10SFBXaFA1WFBIdVM0WT0iLCJtYWMiOiJhNjA1ZjViMmIyNDE3MDg4NzU0ZjYxMzE1YmZjYTcwZDg5OTg2MGI0ZmFiZTkxYWY5OGRkZDJmNjUwMjVhMzdhIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-11.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Lara-HD.html?player=public01&at=eyJpdiI6ImVNZnNkbEJMc1RjczIwRHZIekQzOHc9PSIsInZhbHVlIjoid3VYSUxHMU9JQ3I0Wlg1dTdma2FJSW1XS05MNW1xc2RDVzN2OXBlTUQwND0iLCJtYWMiOiJmZWVhYjg3NjM0NmIzNWFhZjFlMTEzMzMwYTQwNjkzNjZmZTZjY2RkYzFlYWEyODMxZjA5YTM0NTViYmYxYzJhIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-12.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Amazonas-Presi-HD.html?player=public01&at=eyJpdiI6ImJUSzhWZTA4U3pNQ1BkdXhpMWFGVmc9PSIsInZhbHVlIjoiUzdpd0g5bXZmOExxODY4bzQ3RFp4K3NkcnhxcG9yc1NjZXZPTHF6SWNxTT0iLCJtYWMiOiI3MjlhOTBiYzQxNmFjNTVhYTJkZDYyMWZkNjliYmU4N2YyNjMzZjU4MTk2ZTJjYzk2Mjc2ZDNiYjcwNTJiNmMyIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-13.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Margarita-Full-Online-Low.html?player=public01&at=eyJpdiI6ImtHRW5vNlFubGNseW1KemlqcEtvVHc9PSIsInZhbHVlIjoickJqWlNyelpSdlYxajVKWUpKSHQ0N0pFdkljMUYzdHJNd3F6K0x0YnVGZz0iLCJtYWMiOiJkMTEwY2IzZGUwYjMwMjdmYmQ5NzgwNDA3MjE2ZDJiM2U1ZDBjNjZiN2Q4Yzc3NTU0NjU4OTNjYjc1YjU3YjNjIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-14.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/Perfiles-Resumen-Online-1.html?player=public01&at=eyJpdiI6ImQzdC9YNzMrOFJlc3VZQVR4UjdwVGc9PSIsInZhbHVlIjoiaWF5eDhKZjlpWG8vM1JKQi9JcEcwQ3BxWXozUXRNeGVSV0ZNMG1VL2dURT0iLCJtYWMiOiI0MDMyMTEwZDE1ZGU0OTU0M2I0ZDVjMDIzZDM5ZTY4MDE5M2U0YWRlZTkwMGJmMjgyYzRmMDViYTg3MTRiMmJhIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-15.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/Estefan-a-Cerv-Final-final.html?player=public01&at=eyJpdiI6InQrWEJ6aTRMSERpclJtM01kMHR0SGc9PSIsInZhbHVlIjoiODBzN2Y2ZDhVMHJGUnFSZGUyZGJ4Q3RVMUtjRkZFWEo2NXRxcCtNOXFOcz0iLCJtYWMiOiI1OWNkMWQ0ZWRiNTg2MTc1NWUyMzU0YzU2MDVmZWMzNTA4NWZjNTFjNGU2NjA5NWM0YTRlY2Q2NDU4MWUwNTVjIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-16.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/Marialejandra-Online-1.html?player=public01&at=eyJpdiI6InlRWExHYkdBdGR6aS8xWGJjcGpjWXc9PSIsInZhbHVlIjoiYVg1N0p2WXdzaTZkU3d0MzhKbEROWjVhL0pybEg4MjBaUFBBQ29POU01az0iLCJtYWMiOiJiODE4ODhmNzA4NTViZTQ4YzRjM2E0OGJjZGI0ZWJhOTU1NTg2ZWVmNTAxYmM1MDhlYzU1YmFmYTUwOWU3ZjUyIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-17.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/Luis-Bravo-Online-1.html?player=public01&at=eyJpdiI6Ill2a3hCdGdRV1JIcHR3T05peG5zTmc9PSIsInZhbHVlIjoiREUwZFZiTmw3MGtRcTBVdlFSZGh5R1pTVnFhZmtvWWZ0NTVNVEdvdlVGOD0iLCJtYWMiOiIwNDZkOWM1N2E0Mjg2NmU3OWU5MzE4MWYzMWMzY2ExNTY5MjFjOWEwZGNlMWFlZTMyNTRlOTk4ZGQ4ZjM5NGVkIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  //   {
  //     type: tiposArray[2],
  //     category: 'concepto',
  //     title: '',
  //     desc: '',
  //     img: '../img/propaganda/plan/video-plan-18.JPG',
  //     url: 'https://media.publit.io/file/Propaganda/PlanPais/PP-Trujillo-HD.html?player=public01&at=eyJpdiI6Ik9pNmFDVUNtZ3M3YW5EeW5SV3o1RVE9PSIsInZhbHVlIjoibmNwcS85UG9Pc1pJSFVSSkRVVEMwRjB1R1ZRTUR4N084VVBneEJhQUlRdz0iLCJtYWMiOiI4NmJjNjkyMjIwOTQ5NDg3MWU1YjU1OTJiZWVhYTI5MjViOGFiOTJiNmMwOTQ2MmUwNTZiOTI5NzMxZjFlODVhIiwidGFnIjoiIn0=',
  //     tags: [],
  //   },
  // ];

  // const linksArray = [...publicidadArray, ...propagandaArray];

  return {
    selectedsType,
    selectedsCategories,
    setSelectedsCategories,
    setSelectedsType,

    tiposArray,
    categoriasArray,
    // linksArray,
  };
};

export default useVideosHook;
