export const SET_DATA_AUTH = 'SET_DATA_AUTH';
export const SET_LOGOUT = 'SET_LOGOUT';

export const GET_CLIENTES = 'GET_CLIENTES';
export const SET_CLIENTE_ACTIVO = 'SET_CLIENTE_ACTIVO';

export const GET_SUSCRIPCIONES = 'GET_SUSCRIPCIONES';
export const SET_SUSCRIPCION_ACTIVO = 'SET_SUSCRIPCION_ACTIVO';

export const GET_USERS = 'GET_USERS';
export const USERS_ERROR = 'USERS_ERROR';

export const SET_CAJA_FUERTE = 'SET_CAJA_FUERTE';

export const GET_ALL_PUBLICO_PELICULAS = 'GET_ALL_PUBLICO_PELICULAS';
export const GET_ALL_PELICULAS = 'GET_ALL_PELICULAS';
export const SET_PELICULAS_ACTIVO = 'SET_PELICULAS_ACTIVO';

export const GET_ALL_VIDEOS = 'GET_ALL_VIDEOS';
export const SET_VIDEOS_ACTIVO = 'SET_VIDEOS_ACTIVO';
export const SET_ROUTE_CATEGORIA = 'SET_ROUTE_CATEGORIA';

export const GET_ALL_BLOG = 'GET_ALL_BLOG';
export const SET_BLOG_ACTIVO = 'SET_BLOG_ACTIVO';
export const GET_ALL_PUBLICO_BLOG = 'GET_ALL_PUBLICO_BLOG';
export const CLEAR_ALL_PUBLICO_BLOG = 'CLEAR_ALL_PUBLICO_BLOG';

export const GET_ALL_TIPOS_BOLETIN = 'GET_ALL_TIPOS_BOLETIN';
export const SET_TIPOS_BOLETIN_ACTIVO = 'SET_TIPOS_BOLETIN_ACTIVO';
export const GET_ALL_PUBLICO_TIPOS_BOLETIN = 'GET_ALL_PUBLICO_TIPOS_BOLETIN';
export const IS_FORM_TIPOS_BOLETIN = 'IS_FORM_TIPOS_BOLETIN';
export const IS_LIST_TIPOS_BOLETIN = 'IS_LIST_TIPOS_BOLETIN';
export const CLEAR_ALL_PUBLICO_TIPOS_BOLETIN =
  'CLEAR_ALL_PUBLICO_TIPOS_BOLETIN';

export const GET_ALL_NEWSLETTER = 'GET_ALL_NEWSLETTER';
export const SET_NEWSLETTER_ACTIVO = 'SET_NEWSLETTER_ACTIVO';
export const GET_ALL_PUBLICO_NEWSLETTER = 'GET_ALL_PUBLICO_NEWSLETTER';
export const CLEAR_ALL_PUBLICO_NEWSLETTER = 'CLEAR_ALL_PUBLICO_NEWSLETTER';

export const GET_CODIGOS = 'GET_CODIGOS';
export const GET_ALL_CODIGOS = 'GET_ALL_CODIGOS';
export const GET_ONE_CODIGOS = 'GET_ONE_CODIGOS';
export const SET_CODIGOS_ACTIVO = 'SET_CODIGOS_ACTIVO';
export const SET_CODIGOS_ACTIVO_CINE = 'SET_CODIGOS_ACTIVO_CINE';

export const SET_SECTION_LANDING = 'SET_SECTION_LANDING';
export const OPEN_MENU = 'OPEN_MENU';

export const SET_VIDEO_PROPAGANDA = 'SET_VIDEO_PROPAGANDA';
export const SET_VIDEO_CINE = 'SET_VIDEO_CINE';
export const SET_IS_CODE_CONTACTO = 'SET_IS_CODE_CONTACTO';
export const SET_IS_LEYENDA = 'SET_IS_LEYENDA';
export const SET_IS_LEYENDA_IMG = 'SET_IS_LEYENDA_IMG';
export const SET_IS_SUSCRIPTION = 'SET_IS_SUSCRIPTION';
export const SET_LANG = 'SET_LANG';
