import {
  GET_ALL_VIDEOS,
  SET_ROUTE_CATEGORIA,
  SET_VIDEOS_ACTIVO,
} from '../types';

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  routeCategoria: null,
};

export const videosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VIDEOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case SET_ROUTE_CATEGORIA:
      return {
        ...state,
        routeCategoria: action.payload,
      };
    case SET_VIDEOS_ACTIVO:
      return {
        ...state,
        activo: action.payload,
      };
    default:
      return state;
  }
};
