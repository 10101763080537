import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../../utils/constants';

const Logo = styled.img`
  width: ${({ size }) => size || '10vw'};

  @media ${device.mobile} {
    width: ${({ size }) => size || '30vw'};
    margin-bottom: 1.3em;
  }
`;

const LogoComponent = ({ size }) => {
  return <Logo src="img/logo.png" size={size} style={{ cursor: 'pointer' }} />;
};

export default LogoComponent;
