import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { archivosFiles } from '../../../../services/api';
import { getAllVideosAction } from '../../../../store/actions/videos.action';
import { SET_ROUTE_CATEGORIA } from '../../../../store/types';
import { colors, device } from '../../../../utils/constants';
import { SectionPublicidad } from '../../publicidad/components';
import PublicidadComponent from '../../publicidad/publicidad';
import LayoutMenu from '../home/layoutHome';
import CheckendWidget from './CheckendWidget';
import useVideosLinksHook from './VideosLinksHook';

const ContainerOptions = styled.div`
  /* background-color: red; */
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 2em;

  color: white;

  @media ${device.mobile} {
    width: 100vw;
    padding-left: 1vw;
    flex-wrap: wrap;
    margin-top: ${({ mt }) => mt ?? '0.2em'};
    margin-bottom: ${({ mb }) => mb ?? '1em'};
  }
`;

const PortafolioNew = () => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery({ query: device.mobile });

  const location = useLocation();
  const videosStore = useSelector((state) => state.videos);

  const {
    tiposArray,
    categoriasArray,
    selectedsType,
    setSelectedsType,
    selectedsCategories,
    setSelectedsCategories,
  } = useVideosLinksHook();

  const [videos, setVideos] = React.useState(videosStore.all);

  useEffect(() => {
    const { state } = location;
    const { categoria } = state || {};

    if (categoria) {
      dispatch({
        type: SET_ROUTE_CATEGORIA,
        payload: categoria,
      });

      // Use a promise to handle the delay instead of setTimeout
      new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
        setSelectedsCategories([categoria]);
        dispatch({ type: SET_ROUTE_CATEGORIA, payload: null });
      });
    }

    // Fetch data immediately
    getAllVideosAction(dispatch);
  }, []);

  useEffect(() => {
    const filteredVideos = videosStore.all.filter((video) => {
      if (
        selectedsType.includes(video.tipo) &&
        video.categorias.some((categoria) =>
          selectedsCategories.includes(categoria)
        )
      ) {
        return video;
      }
    });

    if (selectedsType.length === 0 && selectedsCategories.length === 0) {
      setVideos(videosStore.all);
      return;
    }

    setVideos(filteredVideos);
  }, [selectedsType, selectedsCategories]);

  function CheckboxComponent({
    label,
    all,
    one = false,
    selected,
    setSelected,
    isCategory = false,
  }) {
    const findOne = all.find((el) => el.label === label);

    const checked = one
      ? selected === findOne.id
      : selected.includes(findOne.id);

    const handleChange = () => {
      // if (one) {
      //   return setSelected(findOne.id);
      // }

      if (!checked) {
        setSelected((prev) => {
          // Verifica si la opción ya existe antes de agregarla
          if (!prev.includes(findOne.id)) {
            return [...prev, findOne.id];
          }
          return prev;
        });
      } else {
        setSelected((prev) => prev.filter((item) => item !== findOne.id));
      }
    };

    return (
      <div className="checkbox-container">
        <CheckendWidget
          active={checked}
          onClick={handleChange}
          isCategory={isCategory}
        />
        {/* <input
          type="checkbox"
          id="checkbox"
          checked={checked}
        /> */}
        <label
          htmlFor="checkbox"
          style={{
            marginLeft: '0.6em',
            fontSize: mobile ? '0.8em' : isCategory ? '1em' : '1.2em',
            fontFamily: isCategory ? 'MontserratLight' : 'MontserratMedium',
          }}
          onClick={handleChange}
        >
          {label.charAt(0).toUpperCase() + label.slice(1)}
        </label>
        <style jsx>{`
          .checkbox-container {
            display: flex;
            align-items: center;
            margin-top: 0.7em;
            /* font-size: ${mobile ? '0.8em' : isCategory ? '1em' : '1.2em'}; */
          }
          /* input[type='checkbox'] {
            appearance: none;
            background-color: ${colors.primary};
            border: 2px solid white;
            padding: ${mobile ? '0.5em' : '0.5em'};
            border-radius: 20px;
            display: inline-block;
            position: relative;
          }
          input[type='checkbox']:checked {
            background-color: white;
          } */
        `}</style>
      </div>
    );
  }

  const categoriaMobile = (
    <>
      <ContainerOptions mt="1em" mb="0.2em">
        <CheckboxComponent
          key={categoriasArray[0].id}
          label={categoriasArray[0].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
        />
        <CheckboxComponent
          key={categoriasArray[1].id}
          label={categoriasArray[1].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
        />
        <CheckboxComponent
          key={categoriasArray[2].id}
          label={categoriasArray[2].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
        />
      </ContainerOptions>
      <ContainerOptions mt="0em" mb="2em">
        <CheckboxComponent
          key={categoriasArray[3].id}
          label={categoriasArray[3].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
        />
        <CheckboxComponent
          key={categoriasArray[4].id}
          label={categoriasArray[4].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
        />
        <CheckboxComponent
          key={categoriasArray[5].id}
          label={categoriasArray[5].label}
          all={categoriasArray}
          selected={selectedsCategories}
          setSelected={setSelectedsCategories}
          isCategory
        />
      </ContainerOptions>
    </>
  );

  return (
    <LayoutMenu>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          marginTop: '6vw',
        }}
      >
        <Fade duration={1500}>
          <ContainerOptions mt="5em">
            {tiposArray.map((tipo) => (
              <CheckboxComponent
                key={tipo.id}
                label={tipo.label}
                mb="0em"
                all={tiposArray}
                selected={selectedsType}
                setSelected={setSelectedsType}
              />
            ))}
          </ContainerOptions>
          {mobile ? (
            categoriaMobile
          ) : (
            <ContainerOptions>
              {categoriasArray.map((categoria) => (
                <CheckboxComponent
                  key={categoria.id}
                  label={categoria.label}
                  all={categoriasArray}
                  selected={selectedsCategories}
                  setSelected={setSelectedsCategories}
                  isCategory
                />
              ))}
            </ContainerOptions>
          )}
        </Fade>

        <SectionPublicidad>
          {videos.map((el, index) => {
            return (
              <PublicidadComponent
                key={index}
                title={el.titulo}
                desc={el.desc}
                url={el.link}
                img={`${archivosFiles}/${el.image.folder}/${el.image.nombre}`}
              />
            );
          })}
        </SectionPublicidad>
      </div>
    </LayoutMenu>
  );
};

export default PortafolioNew;
