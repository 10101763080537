import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../utils/constants';

const Container = styled.div`
  width: 420px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4em;
  padding: 0em 2em;

  &:hover .something {
    color: #fcee21;
    border-bottom: 1px solid #fcee21;
  }

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0em 0em;
  }
`;

const CardPerson = ({ name, text, foto }) => {
  const mobile = useMediaQuery({ query: device.mobile });

  const size = mobile ? '200px' : '13vw';

  return (
    <Container>
      <div
        style={{
          width: size,
          height: size,
          borderRadius: mobile ? '100px' : '90px',
          position: 'relative',
          marginTop: '1em',
        }}
      >
        <img
          alt="foto"
          src={`img/nosotros/${foto}`}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            objectFit: 'cover',
            objectPosition: mobile ? '50% 50%' : '50% 30%',
          }}
        />
      </div>
      <div
        style={{
          fontFamily: 'MontserratBold',
          marginTop: mobile ? '1.2em' : '2vw',
          width: mobile ? '100%' : '90%',
          textAlign: 'center',
          color: 'white',
          fontSize: mobile ? '1.4em' : '1.3vw',
        }}
      >
        {name}
      </div>
      <div
        style={{
          fontSize: mobile ? '1em' : '1.3vw',
          fontFamily: 'MontserratLight',
          color: 'white',
          width: '100%',
          textAlign: 'center',
          marginTop: mobile ? '1.2em' : '0.6vw',
        }}
      >
        {text}
      </div>
    </Container>
  );
};

export default CardPerson;
