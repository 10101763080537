import * as React from 'react';
import { slideInUp } from 'react-animations';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { Modal, ModalBody } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import { device } from '../../../utils/constants';

const bounceAnimation = keyframes`${slideInUp}`;

export const Container = styled.div`
  width: ${({ full }) => (full ? '100vw' : '31vw')};
  height: ${({ full }) => (full ? '30vw' : '20vw')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #000;
  margin-top: 3vw;

  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media ${device.mobile} {
    width: 100%;
    height: 15em;
  }
`;

export const BodyInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0);
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;

  animation: 0.5s ${bounceAnimation};
`;
export const Info = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-family: 'FuturaBold';
    font-size: 3.6em;
    color: white;
    text-align: center;
  }

  @media ${device.mobile} {
    h3 {
      font-size: 2.8em;
    }
  }
`;

export const Links = styled.h2`
  cursor: pointer;
  text-align: center;
`;

const CardPropagandaComponent = ({ img, title, desc, url, full, onClick }) => {
  const [isHover, setIsHover] = React.useState(false);
  const [isModal, setIsModal] = React.useState(false);

  const toggle = () => {
    setIsModal(!isModal);
  };

  const info = (
    <BodyInfo>
      <Info>
        <center>
          <h3>{title}</h3>
        </center>
        {url === '' ? <h1>{desc}</h1> : <Links onClick={toggle}>{desc}</Links>}
      </Info>
    </BodyInfo>
  );

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  return (
    <Container
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      img={img}
      full={full}
      onClick={onClick}
    >
      {isMobileDevice ? info : isHover ? info : <></>}

      <Modal isOpen={isModal} toggle={toggle} centered={true} size="lg">
        <ModalBody style={{ backgroundColor: 'black' }}>
          <ReactPlayer
            width={isMobileDevice ? '100%' : null}
            url={url}
            playing
            controls
          />
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default CardPropagandaComponent;
