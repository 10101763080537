import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as ROUTES from '../../../../router/routes';
import { colors, device } from '../../../../utils/constants';
import Menu from '../menu';
import HideMenu from '../menu/HideMenu';
import useMenuHook from '../menu/MenuHook';
import LogoComponent from './components/Logo';

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};

  @media ${device.mobile} {
  }
`;

const Body = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: black; */
  flex-direction: column;

  @media ${device.mobile} {
    background-size: cover;
    .type-wrap {
      span {
        font-size: 10vw;
      }
    }
  }
`;

const LogoTop = styled.div`
  position: fixed;
  top: 2vw;
  left: 1.5vw;

  z-index: 999;

  @media ${device.mobile} {
    top: 5vw;
    left: 3vw;
  }
`;

const LayoutMenu = ({ isLogoTop = true, children, menuBlack = false }) => {
  const { hanlderOpenMenu } = useMenuHook();

  useEffect(() => {
    hanlderOpenMenu(false);
  }, []);

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  return (
    <Container>
      <HideMenu />
      <Menu menuBlack={menuBlack} />
      {isLogoTop && (
        <LogoTop>
          <Link to={ROUTES.LANDING_PAGE}>
            <LogoComponent size={isMobileDevice ? '14vw' : '5.5vw'} />
          </Link>
        </LogoTop>
      )}
      <Body>{children}</Body>
    </Container>
  );
};

export default LayoutMenu;
