import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import { getAllPublicoTiposBoletinAction } from '../../../../store/actions';
import { device } from '../../../../utils/constants';
import { Casa, Productora } from '../../landing-page/conocenos/components';
import InfoFotoComponent from '../../newsletter/info_foto';
import { infoHeader } from '../../newsletter/info_header';
import LaLiebreComponent from '../../newsletter/la_liebre';
import ListaNewsletterComponent from '../../newsletter/lista_newsletter';
import LayoutMenu from '../home/layoutHome';
import useMenuHook from '../menu/MenuHook';

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0em 5em;

  @media ${device.mobile} {
    width: 100%;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  // width: 10vw,
  // height: 300px;
  padding: 1vw 4vw 0 1vw;

  @media ${device.mobile} {
    // width: 100px,
    // height: 300px;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px 0 0px;
  }
`;

const BodyNewsletter = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: end;
  justify-content: center;
  width: 100%;

  @media ${device.mobile} {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const Logo = styled.img`
  width: ${({ size }) => size ?? '10vw'};
  padding: 1vw 2vw;

  cursor: pointer;

  @media ${device.mobile} {
    padding: 0vw 0vw;
  }
`;

const NewsletterNew = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();

  const { openMenu } = useMenuHook();

  const mobile = useMediaQuery({ query: device.mobile });

  const tiposBoletinState = useSelector((state) => state.tiposBoletin);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [select, setSelect] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingHeader, setLoadingHeader] = useState(false);

  function getTipo() {
    if (tiposBoletinState.allPublico.length > 0) {
      setSelect(tiposBoletinState.allPublico[0]);
      setInfo(
        infoHeader.find((el) => el.id === tiposBoletinState.allPublico[0].id)
      );
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      getTipo();
      setLoading(true);
      await getAllPublicoTiposBoletinAction(dispatch, null);
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tiposBoletinState.allPublico.length > 0) {
      getTipo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiposBoletinState.allPublico]);

  useEffect(() => {
    setInfo(infoHeader.find((el) => el.id === select?.id));
  }, [select]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <LayoutMenu menuBlack={!openMenu} isLogoTop={false}>
      <Container>
        <Header>
          <Logo
            color="black"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
            size={mobile ? '30vw' : '15vw'}
            src={'img/logoblack.png'}
            onClick={() => {
              history.push('/');
            }}
          />
          <BodyNewsletter>
            <Casa
              color="black"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
              style={{
                lineHeight: 0,
                fontSize: mobile ? '2.3em' : '5vw',
                marginTop: '1em',
              }}
            >
              {info?.title}
            </Casa>
            <Productora
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
              color="black"
              size={'5vw'}
              style={{
                lineHeight: mobile ? '2.5em' : '7vw',
                fontSize: mobile
                  ? '1.4em'
                  : [1, 2, 5].includes(select?.id)
                    ? '3.85vw'
                    : '2.5vw',
                textAlign: 'center',
              }}
            >
              {info?.subtitle}
            </Productora>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
              style={{
                marginBottom: mobile ? '20px' : 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {loading && <Spinner size="sm" style={{ marginRight: '10px' }} />}

              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  style={{ backgroundColor: 'black', borderRadius: '0' }}
                  caret
                >
                  {select?.nombre}
                </DropdownToggle>
                <DropdownMenu>
                  {tiposBoletinState.allPublico.map((el, i) => {
                    return (
                      <DropdownItem
                        style={{
                          backgroundColor: 'transparent',
                          color: 'inherit',
                        }}
                        active={select?.id === el.id}
                        key={i}
                        onClick={() => {
                          setLoadingHeader(true);
                          setSelect(el);
                          setTimeout(() => {
                            setLoadingHeader(false);
                          }, 500);
                        }}
                      >
                        {el.nombre}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </BodyNewsletter>
        </Header>
        {select?.id === 6 ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img
              src={'img/alunizaje.png'}
              style={{
                width: '70%',
              }}
            />
          </div>
        ) : (
          select &&
          info && (
            <>
              {loadingHeader ? (
                <center>
                  <Spinner size="sm" style={{ marginBottom: '20px' }} />
                </center>
              ) : select?.id === 5 ? (
                <LaLiebreComponent info={info} />
              ) : (
                <InfoFotoComponent select={select} info={info} />
              )}
              <ListaNewsletterComponent select={select} />
            </>
          )
        )}
      </Container>
    </LayoutMenu>
  );
};

export default NewsletterNew;
