/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MIS_VIDEOS } from '../../../common/const';
import {
  SET_IS_LEYENDA,
  SET_IS_LEYENDA_IMG,
  SET_IS_SUSCRIPTION,
  SET_LANG,
} from '../../../store/types';
import Home from './home';

const WebNewsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const layoutState = useSelector((state) => state.layout);
  const landingState = useSelector((state) => state.landing);

  const [lang, setLang] = useState(
    window.localStorage.getItem('i18nextLngPLP')
  );

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const changeLang = (langs) => {
    window.localStorage.setItem('i18nextLngPLP', langs);
    setLang(langs);
    dispatch({
      type: SET_LANG,
      payload: langs,
    });
  };

  // Esta función manejará el evento de cierre de ventana
  const handleWindowClose = () => {
    dispatch({
      type: SET_IS_SUSCRIPTION,
      payload: true,
    });
  };

  useEffect(() => {
    // Agrega un evento de cierre de ventana cuando el componente se monta
    window.addEventListener('beforeunload', handleWindowClose);

    const fetchData = () => {
      const x = localStorage.getItem(MIS_VIDEOS);
      if (x === undefined || x === null) {
        localStorage.setItem(MIS_VIDEOS, false);
      }
      dispatch({
        type: SET_IS_LEYENDA,
        payload: false,
      });
      dispatch({
        type: SET_IS_LEYENDA_IMG,
        payload: false,
      });

      localStorage.removeItem('PLPtoken');
      localStorage.removeItem('persist:PLP');

      setTimeout(() => {
        setModal(layoutState.isSuscription);
        dispatch({
          type: SET_IS_SUSCRIPTION,
          payload: false,
        });
      }, 200);
    };
    fetchData();
    // Retira el evento de cierre de ventana cuando el componente se desmonta
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, []);

  return (
    <div>
      {/* <Navbar lang={lang} changeLang={changeLang} /> */}
      <Home t={t} />
      {/* <Conocenos /> */}
      {/* 
      <Publicidad />
      <Tags /> */}
      {/* <Concepto />
      <Noticias t={t} /> */}
      {/* <LaCaja />
      <Footer />

      <Modal isOpen={modal} toggle={toggle} centered>
        <FormNewsletter close={() => setModal(false)} />
      </Modal> */}
    </div>
  );
};

export default WebNewsPage;
