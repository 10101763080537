import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../../utils/constants';
import LayoutMenu from '../home/layoutHome';

export const LinkEmail = styled.a`
  color: white;
  font-size: 1.5vw;
  font-family: 'MontserratLight';
  margin-top: 2vw;
  text-align: ${(center) => (center ? 'center' : 'right')};
  text-decoration: none;

  &:hover {
    font-weight: 900;
  }

  @media ${device.mobile} {
    font-size: 7vw;
  }
`;

const Title = styled.div`
  color: white;
  font-size: 1.5vw;
  font-family: 'MontserratLight';
  margin-top: 2vw;
  text-align: ${(center) => (center ? 'center' : 'right')};

  a {
    font-size: 1.5vw;
  }

  @media ${device.mobile} {
    font-size: 6vw;
    line-height: vw;

    a {
      font-size: 6vw;
    }
  }
`;

const SocialBotones = styled.div`
  top: 10vw;
  left: 3.4vw;
  /* background-color: black; */
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 1.5em 0;
  align-items: center;
`;

const Iconos = styled.img`
  width: ${({ w }) => w ?? '2vw'};
  margin-right: 15px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }

  @media ${device.mobile} {
    width: ${({ w }) => w ?? '1.7em'};
  }
`;

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const ContactoNew = () => {
  const { t } = useTranslation();

  const mobile = useMediaQuery({ query: device.mobile });

  return (
    <LayoutMenu>
      <Fade duration={1500}>
        <Title center>
          <LinkEmail href="mailto:contacto@palospanas.com?subject=Asunto&body=Cuerpo del correo">
            contacto@palospanas.com
          </LinkEmail>
          <br />
          C.C. Mata de Coco PB
          {mobile ? <br /> : ' '}
          Local 13 -14 -15
          <br />
          <LinkEmail href="tel:+584123793854">+584123793854</LinkEmail>
        </Title>
      </Fade>
      <br />
      <br />
      <SocialBotones>
        <Fade duration={1500} delay={300}>
          <Iconos
            onClick={() =>
              openInNewTab(
                'https://www.youtube.com/channel/UC3ra91gf1g2RToJ7nwjRG8g'
              )
            }
            src={'img/youtube.png'}
          />

          <Iconos
            onClick={() =>
              openInNewTab(
                'https://ve.linkedin.com/company/pa-los-panas-producciones'
              )
            }
            src={'img/linkend.png'}
          />
          <Iconos
            onClick={() =>
              openInNewTab(
                'https://instagram.com/palospanasprod?igshid=1t0x76sa705f0'
              )
            }
            src={'img/insta.png'}
          />
          <Iconos
            onClick={() => openInNewTab('https://twitter.com/palospanasprod')}
            src={'img/twitter.png'}
          />
          <Iconos
            onClick={() => openInNewTab('https://vimeo.com/palospanas')}
            src={'img/vimeo.png'}
          />
          <Iconos
            w={mobile ? '3em' : '3.2vw'}
            onClick={() =>
              openInNewTab(
                'https://www.imdb.com/search/title/?companies=co0531373'
              )
            }
            src={'img/imdb.png'}
          />
          <Iconos
            w={mobile ? '1.7em' : '1.8vw'}
            onClick={() =>
              openInNewTab('https://www.tiktok.com/@palospanasprod')
            }
            src={'img/tiktok.png'}
          />
        </Fade>
      </SocialBotones>
    </LayoutMenu>
  );
};

export default ContactoNew;
