import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_MENU } from '../../../../store/types';
export const i18nextLngPLPConst = 'i18nextLngPLP';

const useMenuHook = () => {
  const dispatch = useDispatch();

  const landingStore = useSelector((state) => state.landing);

  const [lang, setLang] = useState(
    window.localStorage.getItem(i18nextLngPLPConst)
  );

  const changeLang = (langs) => {
    window.localStorage.setItem(i18nextLngPLPConst, langs);
    setLang(langs);
  };

  const hanlderOpenMenu = (value = undefined) => {
    dispatch({
      type: OPEN_MENU,
      payload: value,
    });
  };

  return {
    lang,
    changeLang,
    hanlderOpenMenu,
    openMenu: landingStore.openMenu,
  };
};

export default useMenuHook;
